import { isVisibleFormModal } from "./utils";

const policyModal = document.querySelector("#privacy-policy");

let oldURL = null;
const closeModal = () => {
  if (oldURL) {
    window.history.back();
  } else {
    location.href = "/";
  }
};

window.addEventListener("hashchange", (e) => {
  oldURL = e.oldURL;
  if (e.newURL.match("#privacy-policy")) {
    document.body.style.overflow = "hidden";
  } else if (e.oldURL.match("#privacy-policy") && !isVisibleFormModal()) {
    document.body.style.overflow = "auto";
  }
});

window.addEventListener("load", (e) => {
  if (location.href.match("#privacy-policy")) {
    document.body.style.overflow = "hidden";
  }
});

policyModal.addEventListener("click", (e) => {
  if (e.target.classList.contains("modal-wrapper")) {
    closeModal();
  }
});

policyModal.querySelector(".modal-close").addEventListener("click", () => {
  closeModal();
});
